/* eslint-disable jsx-a11y/role-has-required-aria-props */
import {
  CurrentLocationMenu,
  Address as IconAddress,
} from '@ahamove/icons/map';
import { getAutocompleteFn, type Address } from '@ahm/api-wrappers-core';
import { Loader2, X } from 'lucide-react';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { DebouncedInput } from '@/components/debounced-input';
import { Button } from '@/components/ui/button';
import { publicEnv } from '@/config/public-env.config.mjs';
import { useLocationStore } from '@/store';

interface AutocompleteProps {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  address?: Address | null;
  onChange: (address: Address | null) => void;
}

export default function Autocomplete({
  inputProps,
  onChange,
  address,
}: AutocompleteProps) {
  const [addressOptions, setAddressOptions] = useState<Address[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<string>(address?.address ?? '');
  const [empty, setEmpty] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const abortController = useRef<AbortController>();
  const detectLocation = useLocationStore.use.detectLocation();
  const loadingLocation = useLocationStore.use.loading();

  const apiConfig = {
    apiURL: publicEnv.NEXT_PUBLIC_EP_API_URI,
    apiKey: publicEnv.NEXT_PUBLIC_EP_API_KEY,
  };

  const fetchOptions = (query: string) => {
    if (!query || query.length < 2) {
      setLoading(false);
      if (!query || query.length < 1) setAddressOptions(null);
      return;
    }

    abortController.current?.abort();
    abortController.current = new AbortController();
    setLoading(true);

    getAutocompleteFn(
      apiConfig,
      { input: query },
      { signal: abortController.current.signal }
    )
      .then((result) => {
        setAddressOptions(result);
        setLoading(false);
        setEmpty(result.length === 0);
        abortController.current = undefined;
      })
      .catch(() => {
        setAddressOptions([]);
        setLoading(false);
        setEmpty(true);
        abortController.current = undefined;
      });
  };

  const handleSuggestionClick = (suggestion: Address) => {
    setIsOpen(false);
    setValue(suggestion.address);
    onChange(suggestion);
  };

  const handleSelectCurrentLocation = useCallback(() => {
    detectLocation(apiConfig)
      .then((response) => {
        handleSuggestionClick(response);
        setIsOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiConfig, detectLocation]);

  useEffect(() => {
    const existedValue = addressOptions?.some((item) => item.address === value);

    if (existedValue) {
      return;
    }

    fetchOptions(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputClick = () => {
    setIsOpen(true);
  };

  const handleInputChange = (val: string) => {
    setValue(val);
    setIsOpen(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  return (
    <div className="relative">
      <div ref={inputRef}>
        <DebouncedInput
          // disabled={loading || loadingLocation}
          className="h-12 w-full truncate rounded-xl border-neutral-20 bg-neutral-10/30 pl-4 pr-10 text-sm font-bold hover:bg-neutral-30/30 md:h-16"
          onClick={handleInputClick}
          onKeyDown={handleKeyDown}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          placeholder="Nhập vị trí của bạn"
          {...inputProps}
          value={value}
          onChange={handleInputChange}
        />
        <div className="absolute right-3 top-3 flex items-center self-center md:top-5">
          {loading || loadingLocation ? (
            <Loader2 className="size-5 animate-spin text-muted-foreground" />
          ) : value ? (
            <Button
              variant="ghost"
              size="sm"
              className="size-5 p-0"
              onClick={() => {
                setValue('');
                // onChange(null);
              }}
              aria-label="Clear input"
            >
              <X className="size-6" />
            </Button>
          ) : null}
        </div>
      </div>
      {isOpen && (
        <>
          <div
            ref={dropdownRef}
            className="border-gray-300 absolute z-50 mt-1 w-full overflow-y-scroll rounded-md border bg-white shadow-lg md:max-h-[330px]"
            role="listbox"
          >
            {value ? null : (
              <Button
                disabled={loading || loadingLocation}
                role="option"
                variant="secondary"
                className="h-auto w-full justify-start rounded-none bg-white py-3"
                onClick={handleSelectCurrentLocation}
              >
                <CurrentLocationMenu className="mr-3 size-6 flex-none" /> Lấy vị
                trí hiện tại
              </Button>
            )}
            {addressOptions?.map((suggestion, index) => (
              <Button
                variant="ghost"
                className="h-auto w-full items-start justify-start whitespace-pre-wrap rounded-none text-left"
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleSuggestionClick(suggestion);
                  }
                }}
                role="option"
                tabIndex={0}
              >
                <IconAddress className="mr-3 size-6 flex-none" />
                <div className="flex-1 text-sm font-bold">
                  <p> {suggestion.first_label}</p>
                  <p className="w-full text-xs font-medium text-neutral-40">
                    {' '}
                    {suggestion.address}
                  </p>
                </div>
              </Button>
            ))}

            {value && empty ? (
              <div
                role="option"
                className="px-6 py-4 text-center text-sm font-medium text-neutral-40"
              >
                Không tìm thấy địa điểm nào phù hợp
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}
