'use client';

import { Search, X } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../ui/button';
import { useSearchFilters } from '@/api/use-search-filters';
import { DebouncedInput } from '@/components/debounced-input';
interface AutocompleteProps {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}
export default function HeaderSearch({ inputProps }: AutocompleteProps) {
  const { addFilter, removeFilter, activeFilters } = useSearchFilters();
  const [value, setValue] = useState<string>(activeFilters.q ?? '');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value;
    }
  }, [value]);

  const handleSearch = (value: string) => {
    if (value != '') {
      setValue(value);
      addFilter('q', value);
    }
  };

  const handleDelete = () => {
    setValue('');
    removeFilter('q');
  };

  return (
    <div className="relative flex w-full flex-row flex-nowrap items-center">
      <Search className="absolute left-4 top-1/2 size-6 flex-none -translate-y-1/2" />
      {value ? (
        <Button
          variant="ghost"
          size="sm"
          className="absolute right-[15px] top-3 size-6 bg-white p-0 hover:bg-neutral-20 md:top-5"
          aria-label="Clear input"
          onClick={handleDelete}
        >
          <X className="size-5" />
        </Button>
      ) : null}
      <div ref={inputRef} className="w-full">
        <DebouncedInput
          className="inline-flex h-12 w-full items-center justify-start truncate whitespace-nowrap rounded-xl border border-neutral-20 bg-neutral-10/30 px-4 py-2 pl-12 text-sm font-bold ring-offset-background transition-colors hover:bg-neutral-30/30 hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 md:h-16 md:flex-1 md:text-base"
          placeholder="Bạn muốn ăn gì?"
          {...inputProps}
          value={value}
          onChange={handleSearch}
        />
      </div>
    </div>
  );
}
